$(document).ready(function () {
    $('.main_menu_toggle').on('click', function (e) {
        $('.main_menu').addClass('show');
        $('body').css({overflow: 'hidden'})
    });

    $('.main_menu--close').on('click', function (e) {
        $('.main_menu').removeClass('show');
        $('body').css({overflow: 'unset'})
    });
});
