import Swiper, { Grid, Navigation, Pagination } from 'swiper';

$(document).ready(function () {

	// accordeons
	$('.accordeon > li').on('click', function (e) {
		$(this).toggleClass('opened');
		$(this).find('> div').slideToggle(300);
	});


	// modals
	$('.js-password').on('click', (e) => {
	  const target = $(e.currentTarget);
	  const input = target.closest('.registration__input').find('input');
    const type = input.attr('type');

    target.toggleClass('hidden');

    if (type === 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  })

  $('.js-modal-close').on('click', (e) => {
    e.preventDefault();
    $(e.currentTarget).closest('.js-modal').hide();
    $(e.currentTarget).closest('.js-modal').attr('data-step', 1);
    $(`[data-reg-step]`).hide()
    $(`[data-reg-step="1"]`).fadeIn(300)
  })

  $('.js-btn-registration').on('click', (e) => {
    e.preventDefault();
    $('.js-modal-registration').show();
  })

  $('.js-btn-pay').on('click', (e) => {
    $('.js-modal-pay').show();
  })

  $('.js-btn-success').on('click', (e) => {
    $('.js-modal-success').show();
  })

  $('.js-registration-next').on('click', (e) => {
    e.preventDefault();
    const nextStep = $(e.currentTarget).data('nextStep');
    $(e.currentTarget).closest('.js-modal').attr('data-step', nextStep);
    $(`[data-reg-step]`).hide()
    $(`[data-reg-step=${nextStep}]`).fadeIn(300)
  })

  if (window.innerWidth < 901) {
    $('.js-title-toggle').on('click', (e) => {
      $(e.currentTarget).toggleClass('active');
      $(e.currentTarget).next().slideToggle(200);
    })
  }

  const personalSliders = $('.js-personal-slider')

  if (personalSliders.length) {
    personalSliders.each((i, el) => {
      const swiperPersonal = new Swiper(el.querySelector('.swiper'), {
        modules: [Navigation, Pagination, Grid],
        slidesPerView: 1,
        grid: {
          rows: 3,
          fill: "row",
        },
        spaceBetween: 15,
        pagination: {
          el: el.querySelector('.swiper-pagination'),
          type: 'bullets',
        },
        breakpoints: {
          768: {
            slidesPerView: 'auto',
            spaceBetween: 15,
            navigation: {
              nextEl: el.querySelector('.personal__block-icon-next'),
            },
            pagination: {
              el: el.querySelector('.swiper-pagination'),
              type: 'bullets',
            },
            grid: null
          },
          901: {
            slidesPerView: 'auto',
            spaceBetween: 15,
            navigation: {
              nextEl: el.querySelector('.personal__block-icon-next'),
            },
            pagination: false,
            grid: null,
          }
        },
      });
    })
  }
});
